import React, { useState } from 'react'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

type Prop = {
    title : string|undefined,
    environment : string|undefined,
    errorMessage : string|undefined,
    handleChangeTitle : (value : String)  => void,
    handleChangeEnvironment : (value : String) => void
}

const SecondStep : React.FC<Prop> = ({title, environment, errorMessage, handleChangeTitle, handleChangeEnvironment}) => {
    return <div>
        <TextField label="Nombre de la app" variant="outlined" onChange={(event) => handleChangeTitle(event.target.value)} value={title} error={!!errorMessage} helperText={errorMessage} />

        <FormControl component="fieldset">
            <FormLabel component="legend">Environment</FormLabel>
            <RadioGroup aria-label="environment" name="environment" value={environment} onChange={(event) => handleChangeEnvironment(event.target.value)}>
                <FormControlLabel value="production" control={<Radio />} label="Production" />
                <FormControlLabel value="test" control={<Radio />} label="Test" />
            </RadioGroup>
        </FormControl>

    </div>
}

export default SecondStep