import React from 'react'

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

type Prop = {
    id : string,
    icon : string,
    name : string,
    description : string,
    handleClick : (appId : string) => void
}

const AppTypeButton : React.FC<Prop> = ({id, icon, name, description, handleClick}) => {

    return <Paper elevation={4}>
            <Grid container spacing={3} justifyContent="space-evenly">
                <Grid item xs>
                    <Avatar alt="Remy Sharp" src={`/assets/${icon}`} />
                </Grid>
                <Grid item container direction="column" xs={9}>
                    <Typography>{name}</Typography>
                    <Typography>{description}</Typography>
                </Grid>
                <Grid item xs>
                    <IconButton color="primary" aria-label="add to shopping cart" onClick={() => { console.log(id)} }>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
}

export default AppTypeButton