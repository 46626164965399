import React from 'react'
import Alert from '@material-ui/lab/Alert';
import { CircularProgress, IconButton, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type Prop = {
    backgroundJob : BackgroundJob,
    handleClose : (job_id : string) => () => void
}

const BackgroundJobItem : React.FC<Prop> = ({backgroundJob: {job_id, message, start_date, finished, success}, handleClose }) => {
    const severity = !finished ? 'info' :  success ? 'success' : 'error'
    return  <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Alert 
        variant="filled"
        severity={severity}
        action={ finished &&
            <IconButton 
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose(job_id)}
            >
            <CloseIcon fontSize="inherit" />
            </IconButton>
        }
        >
                { message }
                &nbsp;
                { !finished && <CircularProgress color='secondary' size="18px"/> }
            </Alert>
        </Slide>
}

export default BackgroundJobItem