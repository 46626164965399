import React, { useEffect, useState } from 'react'

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import CreateAppData from '../../entities/CreateAppData';
import AppTypeConfiguration from '../../entities/AppTypeConfiguration';

import getAppConfiguration from '../../services/api/getAppConfiguration';

import SecondStep from './SecondStep'
import ThirdStep from './ThirdStep';
import FirstStep from './FirstStep';

type Prop = {

}

function getSteps() {
    return ['Selecciona tipo', 'Nombrala', 'Creando'];
}


const CreateApp : React.FC<Prop> = () => {
    const [configuration, setConfiguration] = useState<AppTypeConfiguration[]|undefined>()
    useEffect( () => {
        let isMounted = true
        try{
            if(!configuration){
                getAppConfiguration().then( (config) => isMounted && setConfiguration(config) )
            }
        }catch(error : any){
            console.error(error)
        }
        return () => { 
            console.log("Desmontando")
            isMounted = false 
        }
    }, [])


    const [activeStep, setActiveStep] = useState<number>(0);
    const [createAppData, setCreateAppData] = useState<CreateAppData>({idAppType: '', environment: '', title: ''})
    const [errorMessage, setErrorMessage] = useState<string>('');

    const steps = getSteps();

    const handleSelectApp = (appId : string) => {
        const { id, template_name } = configuration.find(x => x.id === appId)
        setCreateAppData( (prevData) => ({...prevData, idAppType: id, title: template_name}))
        handleNext()
    }
    const handleChangeTitle = (title : string) => {
        setCreateAppData( (prevData) => ({...prevData, title}))
    }
    const handleChangeEnvironment = (environment : string) => {
        setCreateAppData( (prevData) => ({...prevData, environment}))
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

      const handleErrorOnCreate = (errorMessage : string) => {
        setErrorMessage(errorMessage)
        handleBack()
      }
  
      const {title, environment, idAppType} = createAppData
      const validateData = title != '' && environment != '' && idAppType != ''

      const getStepContent = () => {
        switch (activeStep) {
          case 0:
            return configuration ? <FirstStep configuration={configuration} handleSelectApp={handleSelectApp}/> : <CircularProgress/>;
          case 1:
            return <SecondStep title={title} environment={environment} errorMessage={errorMessage} handleChangeTitle={handleChangeTitle} handleChangeEnvironment={handleChangeEnvironment}/>;
          case 2:
              const logo = configuration.find(x => x.id === idAppType).icon
            return <ThirdStep appData={createAppData} logo={logo} back={handleErrorOnCreate}/>;
          default:
            return 'Unknown step';
        }
      }

    return (
        <div>
            <Typography variant="h1">Create an App</Typography>
            <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <Container>
                {getStepContent()}
                { 
                activeStep === 1 ? 
                    <div>
                        <Button onClick={handleBack}>
                            Back
                        </Button>
                        <Button disabled={!validateData} onClick={handleNext}>
                            Siguiente
                        </Button>
                    </div>
                    : 
                    null
                }
            </Container>
        </div>
    )
}

export default CreateApp