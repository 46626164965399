import React from "react"
import PropTypes from "prop-types"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const WebAppSelectable = (props) => {
	const [app, setApp] = useState(props.app)
		
	const toggleCheckApp = () => {
		app.checked = !app.checked;
		this.setState({app});
	};
    return (
      <React.Fragment>
      	<ListItem key={app.name} onClick={this.toggleCheckApp}>
      		<ListItemIcon>
      			<Checkbox checked={app.checked}/>
      		</ListItemIcon>
      		<ListItemText primary={app.name}/>
      	</ListItem>
      </React.Fragment>
    );
}

WebAppSelectable.propTypes = {
	app: PropTypes.shape({name: PropTypes.string.isRequired})
}

export default WebAppSelectable
