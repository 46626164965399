// import React from 'react'
// import { render } from '@testing-library/react'
// import CreateApp from '.'

// test("CreateApp renders", async () => {
//     const { findByText } = render(<CreateApp/>)
    
//     const textComponent = await findByText(/CreateApp/)
    
//     expect(textComponent).toHaveTextContent("CreateApp");
// })