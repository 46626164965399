import React from "react"
import PropTypes from "prop-types"
import List from '@material-ui/core/List';

import WebAppSelectable from './WebAppSelectable';

class WebAppsProjectView extends React.Component {
  render () {
  	const {apps} = this.props;
    return (
      <React.Fragment>
	      <List>
	      	{apps.map( 
	      		(app) => 
	      		<WebAppSelectable app={app} key={app.name}/>
	  		)}
  		</List>
      </React.Fragment>
    );
  }
}

WebAppsProjectView.propTypes = {
	apps: PropTypes.array.isRequired,
}

export default WebAppsProjectView
