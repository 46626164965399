import React, { useEffect, useState } from 'react'
import consumer from '../../channels/consumer'

import BackgroundJobItem from '../BackgroundJobItem'

type Prop = {

}

const BackgroundJobs : React.FC<Prop> = () => {
    const [jobs, setJobs] = useState<BackgroundJob[]>([])

    const handleClose = (job_id: string) => () => {
        setJobs( (prev) => prev.filter(d => d.job_id !== job_id))
    }

    useEffect( () => {
        consumer.subscriptions.create("NotificationsChannel", {
            received(data) {
                setJobs( (prev) => {
                    const index = prev.findIndex(x => x.job_id === data.job_id)
                    if( index === -1) return [...prev, data]
                    const newArray = [...prev]
                    newArray[index] = data
                    return newArray
                })
                if(data.finished){
                    const button = document.getElementsByClassName(`btn_job-${data.job_id}`)[0]
                    if(button){
                        if(button.hasAttribute('disabled'))
                            button.attributes.removeNamedItem('disabled')
                        button.innerHTML = "Refresh"
                        button.addEventListener("click", () => window.location.reload())
                    }
                }
            }
        })
    }, [])

    return <div className="background-job-panel">
        {
            jobs.map( x => <BackgroundJobItem key={x.job_id} backgroundJob={x} handleClose={handleClose}/>)
        }
    </div>
}

export default BackgroundJobs