import React from "react"
import PropTypes from "prop-types"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//import { Provider, connect } from 'react-redux';

import WebAppsProjectView from './WebAppsProjectView';
//import { store } from './../store';
// import { getApps } from './../actions';

const getApps = () => []

const getSteps = () => ["Seleccionar Webs", "Seleccionar Stage", "Publicando", "Fin"];
const apps = [
	{
		name: 'ofihotel-cms-staging', 
		last_deploy: new Date(),
		checked: false
	},
	{
		name: 'ofihotel-cms-delamar', 
		last_deploy: new Date(),
		checked: false
	},
];
class UpdateWizard extends React.Component {
	constructor(){
		super();
		this.steps = getSteps();
		this.state = {
			activeStep: 0,
			apps
		};
	}
	componentDidMount(){
		//this.props.getApps();
	}

	handleNext = () => this.setState({ activeStep: this.state.activeStep + 1});

	handleBack = () => this.setState({ activeStep: this.state.activeStep - 1});

	handleReset = () => this.setState({ activeStep: 0});

  render () {
  	const {activeStep, apps} = this.state;
  	const {steps, handleNext, handleBack, handleReset} = this;
    return (
		//<Provider store={store}>
	      <React.Fragment>
	      	<div className="">
	      		<Stepper activeStep={activeStep} alternativeLabel>
	      			{steps.map( (label) => (
	      				<Step key={label}>
	      					<StepLabel>{label}</StepLabel>
	      				</Step>
	  				))}
	      		</Stepper>
	      	</div>
	      	<div>
	      		<Button 
	      		disabled={activeStep === 0}
	      		onClick={handleBack}
	      		>
	      		Back
	      		</Button>
	      		<Button variant="contained" color="primary" onClick={handleNext}>
	      		 Next
	      		</Button>
	      	</div>

	      	<div>
	      		<WebAppsProjectView apps={apps}/>
	      	</div>
	      </React.Fragment>
      //</Provider>
    );
  }
}

UpdateWizard.propTypes = {
	getApps: PropTypes.func.isRequired,
}
export default UpdateWizard;

const mapDispatchToProps = dispatch => ({ 
	getApps: () => dispatch(getApps())
});

//export default connect(null, mapDispatchToProps)(UpdateWizard);
