import React from 'react'
import AppTypeButton from '.'

const story = {
    title: "AppTypeButton",
    component: AppTypeButton
}
export default story
const app = {
    id: "ofitour-cms",
    icon: "OfiTour.svg",
    name: "OfiTour CMS",
    description: "Web App CMS sincronizada con  Ofitour",
    handleClick: (appId: String) => console.log(appId)
}
export const AppTypeButtonExample = () => <AppTypeButton {...app}/>