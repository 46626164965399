import React, { useState, useEffect } from 'react'

import Typography from '@material-ui/core/Typography';

import AppTypeButton from './../AppTypeButton'
import AppTypeConfiguration from '../../entities/AppTypeConfiguration';

type Prop = {
    configuration : AppTypeConfiguration[],
    handleSelectApp : (appId : String) => void
}

const FirstStep : React.FC<Prop> = ({configuration, handleSelectApp}) => {
    return <div>
        <Typography>¿Que app vas a crear?</Typography>
        <hr />
        { configuration.map( x => <div key={x.id}><AppTypeButton key={x.id} {...x} handleClick={handleSelectApp}/><br></br><br></br></div>) }
    </div>
}

export default FirstStep