import React, { useState, useEffect } from 'react'

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';

import CreateAppData from '../../entities/CreateAppData'
import consumer from '../../channels/consumer'

type Prop = {
    appData: CreateAppData,
    logo : string,
    back : (errorMessage: string) => void;
}

interface CreateAppChannelMessage {
    title: string,
    body: string,
    finished?: boolean
}

const ThirdStep : React.FC<Prop> = ({appData, logo, back}) => {
    const {idAppType, environment, title } = appData;
    console.log(appData);

    const [steps, setSteps] = useState<CreateAppChannelMessage[]>([])

    useEffect(() => {
        fetch('create/create', {method: 'POST', headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }, body: JSON.stringify(appData)}).then(response => 
            response.json().then( ({success, message}) => {
                if(success){
                    consumer.subscriptions.create({ channel: "CreateAppChannel", id: message}, {
                        received(data) {
                          console.log("CreateAppChannel", data);
                          setSteps( (prev) => [...prev, {title: data.title, body: data.body, finished: data.finished}])
                          if(data.finished){
                              window.location.href = data.body
                          }
                        }
                    })
                }else{
                    back(message)
                }
            })
        )

    }, [])
    return <div>
        <Avatar alt={idAppType} src={`/assets/${logo}`} /> <Typography>Creando la web {title} ({idAppType}: {environment})</Typography>
        <ul>
            {
                steps.map( (s, i) => <li key={i}>{i===steps.length-1 && !s.finished ? <CircularProgress /> : ''}<Typography>{s.title}</Typography></li>)
            }
        </ul>
    </div>
}

export default ThirdStep